import React from 'react';
import { Container, Col, Row, Table, Button } from "react-bootstrap";
import confirm from "utils/confirm";
import formattingTime from 'utils/formattingTime';

export default function AnotherOfficeEquipment({ equipments, handleTakeDevice, handleReturnDevice }) {
    const tableColumn = {
        tableName: 'equipments',
        tablePrimaryKey: 'id'
    };

    return (
        <Container className="mt-4">
            <Row>
                <Col className="mb-3">
                    <h2 className="text-center">
                        Другое оборудование
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <thead>
                            <tr className="text-center">
                                <th>№</th>
                                <th>Имя оборудования</th>
                                <th>Устройство на руках</th>
                                <th>Кол-во часов пользования устройством за последние 30 дней</th>
                                <th>Кол-во дней пользования устройством за последние 180 дней</th>
                                <th>Зарегестрировать устройство</th>
                                <th>Вернуть устройство</th>
                            </tr>
                        </thead>
                        <tbody>
                            {equipments && equipments.map(equipment =>
                                !!equipment.visibility && (
                                    <tr key={equipment.id} className="text-center">
                                        <td>{equipment.id}</td>
                                        <td className="text-nowrap">{equipment.nameEquipment}</td>
                                        <td className="text-nowrap">{equipment.firstName} {equipment.lastName}</td>
                                        <td>{formattingTime(equipment.hoursOfUsage)}</td>
                                        <td>{equipment.daysOfUsage}</td>
                                        <td>
                                            <Button
                                                className="btn-sm"
                                                disabled={!!equipment.firstName}
                                                onClick={() => confirm({
                                                    header: 'Заполните форму',
                                                    title: 'Скопируйте свой Pl key'
                                                })
                                                    .then((plKey) => handleTakeDevice({
                                                        plKey,
                                                        id: equipment.id,
                                                        tableColumn
                                                    }))}
                                            >
                                                Взять
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="btn-sm"
                                                disabled={!equipment.firstName}
                                                variant="danger"
                                                onClick={() => confirm({
                                                    header: 'Заполните форму для отвязки устройства',
                                                    title: 'Скопируйте свой Pl key'
                                                })
                                                    .then((plKey) => handleReturnDevice({
                                                        plKey,
                                                        id: equipment.id,
                                                        tableColumn
                                                    }))}
                                            >
                                                Вернуть
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
