import React from 'react';
import { Container, Col, Row, Button, Table } from "react-bootstrap";
import confirm from "utils/confirm";
import formattingTime from 'utils/formattingTime';

export default function OfficeDevicesList({ devices, handleTakeDevice, handleReturnDevice }) {
    const tableColumn = {
        tableName: 'devices',
        tablePrimaryKey: 'installationId'
    };

    return (
        <Container fluid={true}>
            <Row>
                <Col className="mb-3">
                    <h2 className="text-center">
                        Офисные устройства
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <thead>
                            <tr className="text-center">
                                <th>Имя устройства</th>
                                <th>Версия приложения и номер сборки</th>
                                <th>Платформа</th>
                                <th>Версия</th>
                                <th>Бренд</th>
                                <th>Модель</th>
                                <th>Производитель</th>
                                {/*<th>Свойства батареи</th>*/}
                                <th>Устройство на руках</th>
                                <th>Дата и время последнего обновления с устройства</th>
                                <th>Кол-во часов пользования устройством за последние 30 дней</th>
                                <th>Кол-во дней пользования устройством за последние 180 дней</th>
                                <th>Управление устройством</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices && devices.map(device =>
                                !!device.visibility && (
                                    <tr key={device.installationId} className="text-center">
                                        <td className="text-nowrap">{device.deviceName}</td>
                                        <td>{device.appVersion} {device.buildNumber}</td>
                                        <td>{device.OS}</td>
                                        <td>{device.Version}</td>
                                        <td>{device.brand}</td>
                                        <td className="text-nowrap">{device.model}</td>
                                        <td>{device.manufacturer}</td>
                                        {/*<td className="text-nowrap">*/}
                                        {/*    {(device.powerState.batteryLevel * 100).toFixed()}%, {" "}*/}
                                        {/*    {device.powerState.batteryState}, режим энерг. - {" "}*/}
                                        {/*    {device.powerState.lowPowerMode ? 'включен' : 'выключен'}*/}
                                        {/*</td>*/}
                                        <td className="text-nowrap">{device.firstName} {device.lastName}</td>
                                        <td>{new Date(device.lastUpdateInfoDevice).toLocaleString('ru')}</td>
                                        <td>{formattingTime(device.hoursOfUsage)}</td>
                                        <td>{device.daysOfUsage}</td>
                                        <td className="text-nowrap">
                                            <Button
                                                className="btn-sm mr-3"
                                                disabled={!!device.firstName}
                                                onClick={() => confirm({
                                                    header: 'Заполните форму',
                                                    title: 'Скопируйте свой Pl key'
                                                })
                                                    .then((plKey) => handleTakeDevice({
                                                        plKey,
                                                        id: device.installationId,
                                                        tableColumn
                                                    }))}
                                            >
                                                Взять
                                            </Button>
                                            <Button
                                                className="btn-sm"
                                                disabled={!device.firstName}
                                                variant="danger"
                                                onClick={() => confirm({
                                                    header: 'Заполните форму для отвязки устройства',
                                                    title: 'Скопируйте свой Pl key'
                                                })
                                                    .then((plKey) => handleReturnDevice({
                                                        plKey,
                                                        id: device.installationId,
                                                        tableColumn
                                                    }))}
                                            >
                                                Вернуть
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
