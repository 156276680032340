import React, { Component } from 'react';
import officeDeviceApi from "../../../officeDeviceApi";
import TrackUsageList from './TrackUsageList';

class TrackUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trackUsage: []
        }
    }

    async componentDidMount() {
        const { data: topDevicesFor7Days } = await officeDeviceApi.get('/get-5-top-devices' , {
            params: {
                days: 7,
                id: 'device_id'
            }
        });
        const { data: topDevicesFor30Days } = await officeDeviceApi.get('/get-5-top-devices' , {
            params: {
                days: 30,
                id: 'device_id'
            }
        });
        const { data: topEquipmentsFor30Days } = await officeDeviceApi.get('/get-5-top-devices' , {
            params: {
                days: 30,
                id: 'CODE'
            }
        });

        this.setState({
            trackUsage: [
                topDevicesFor7Days,
                topDevicesFor30Days,
                topEquipmentsFor30Days
            ]
        })
    }

    render() {
        const { trackUsage } = this.state;

        return (
            trackUsage.map((arrayOfTopDevices, i) => (
                <div key={i}>
                    <TrackUsageList title={arrayOfTopDevices.title} days={arrayOfTopDevices.days} arrayOfTopDevices={arrayOfTopDevices.devices} />
                </div>
            ))
        );
    }
}

export default TrackUsage;
