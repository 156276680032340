import React, { Component } from 'react';
import openSocket from 'socket.io-client';
import officeDeviceApi from '../../officeDeviceApi';
import OfficeDevicesList from './OfficeDevicesList';
import AnotherOfficeEquipment from "./AnotherOfficeEquipment";
import TrackUsage from "./TrackUsageInfo";


export default class OfficeDevicesListController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: null,
            equipments: null
        }
    }

    async componentDidMount() {
        this.socket = openSocket(process.env.REACT_APP_API_URL);

        const { data: { devices, equipments } } = await officeDeviceApi.get('/office-devices');

        this.setState({ devices, equipments });

        this.socket.on('changeData', ({ updatedData: { updatedDevices, updatedEquipments } }) => {
            this.setState({
                devices: updatedDevices,
                equipments: updatedEquipments
            });
        });
    }

    componentWillUnmount() {
        this.socket.close();
    }

    /**
     * Handling taking device. Register devices on current user
     * @param {string} plKey
     * @param {string} id
     * @param {object} tableColumn
     */
    handleTakeDevice = async ({ plKey, id, tableColumn }) => {
        try {
            await officeDeviceApi.put('/put-user', {
                installationId: id,
                tableColumn,
                plKey
            });
        } catch (e) {
            alert('Не получилось зарегестрировать устройство, попытайтесь снова');
        }
    };

    /***
     * Handling return device. Delete registered user
     * @param {string} id
     * @param {object} tableColumn
     */
    handleReturnDevice = async ({ plKey, id, tableColumn }) => {
        try {
            let nameId = '';

            tableColumn.tableName === 'devices' ?
                nameId = 'device_id'
                : nameId = "equipment_id";

           await officeDeviceApi.put('/delete-user', {
                installationId: id,
                tableColumn,
                plKey,
                nameId
            });
        } catch (e) {
            alert('Не получилось отвязать устройство, попытайтесь снова')
        }
    };

    render() {
        const { devices, equipments } = this.state;

        return (
            <>
                <OfficeDevicesList
                    devices={devices}
                    handleTakeDevice={this.handleTakeDevice}
                    handleReturnDevice={this.handleReturnDevice}
                />

                <AnotherOfficeEquipment
                    equipments={equipments}
                    handleTakeDevice={this.handleTakeDevice}
                    handleReturnDevice={this.handleReturnDevice}
                />

                <TrackUsage />
            </>
        );
    }
}
