import { createConfirmation } from 'react-confirm';
import ModalLogin from 'components/OfficeDevicesListController/ModalLogin';

const confirm = createConfirmation(ModalLogin, 10);

/**
 * Confirmation dialog
 * @param options
 */
export default function (options) {
    return confirm({ options });
}
