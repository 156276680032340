import React, { Component } from 'react';
import OfficeDevicesList from './components/OfficeDevicesListController';
import officeDeviceApi from './officeDeviceApi';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            migrationVersion: null
        }
    }

    async componentDidMount() {
        const { data } = await officeDeviceApi.get('/');

        this.setState({
          migrationVersion: data.version
        })
    }

    render() {
        const { migrationVersion } = this.state;

        return(
            migrationVersion ? (
                <div>
                  Server is temporarily closed (reason code #migration-{migrationVersion})
                </div>
              ): <OfficeDevicesList />
        );
    }
}
