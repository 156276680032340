import React from 'react';
import { confirmable } from 'react-confirm';
import { Button, Modal, Form, FormLabel, FormGroup, FormControl } from 'react-bootstrap';

const instructionImage = require('assets/instruction.png');

function ModalLogin({ proceed, cancel, options }) {
    const { header, title } = options;
    let inputRef;

    return (
        <Modal show>
            <Modal.Header>
                <Modal.Title>
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img className="w-100" src={instructionImage} alt="instruction" />
                <Form>
                    <FormGroup controlId='plKey'>
                        <FormLabel column>
                            {title}
                        </FormLabel>
                        <FormControl ref={(input) => inputRef = input} type='text' />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => proceed(inputRef.value)}>
                    Залогиниться
                </Button>
                <Button variant="danger" onClick={cancel}>
                    Отклонить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


export default confirmable(ModalLogin);
