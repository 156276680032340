import React from 'react';
import { Container, Col, Row, Table } from "react-bootstrap";
import formattingTime from 'utils/formattingTime';

export default function TrackUsageList({ arrayOfTopDevices, days, title }) {
    return (
        <Container>
            <Row>
                <Col className="mb-3">
                    <h2 className="text-center">
                        5 самых используемых {title} за последние {days} дней
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <thead>
                        <tr className="text-center">
                            <th>N</th>
                            <th>Устройство</th>
                            <th>Суммарное время пользования устройством</th>
                        </tr>
                        </thead>
                        <tbody>
                        {arrayOfTopDevices && arrayOfTopDevices.map((device, i) => (
                            <tr key={i} className="text-center">
                                <td>{i+1}</td>
                                <td>{device.name}</td>
                                <td>{formattingTime(device.hours)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
