import moment from 'moment';

export default function formattingTime(time) {
    const duration = moment.duration(time);

    const hours = Math.trunc(duration.asHours());
    duration.subtract(hours, 'hours');

    const minutes = Math.trunc(duration.asMinutes());
    duration.subtract(minutes, 'minutes');

    const seconds = Math.trunc(duration.asSeconds());
    const getFormattingDuration = hours + ':' +
        (minutes + '').padStart(2, '0') + ':' +
        (seconds + '').padStart(2, '0');

    return getFormattingDuration;
}
